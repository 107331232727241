import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Flex from '../../elements/flex'
import Grid from '../../elements/grid'
import MobileNavMenuContainer from '../mobileNavMenu'
import DesktopNavMenu from '../desktopNavMenu'
import SectionNavMenu from '../desktopNavMenu/sectionMenu'
import QuickLinksMenu from '../menus/quickLinksMenu'

const t = {
  header: {
    backgroundColor: `#fff`,
    borderBottom: `1px solid #bdc3c7`,
    height: [`4rem`, `5.75rem`]
  }
}

const Header = ({isSubMenuOpen, openAccountLink, forceRootMenu}) => {
  return (
    <>
      <Grid.FullWidthSection as="header" styles={{ 
        borderBottom: t.header.borderBottom, 
        backgroundColor: t.header.backgroundColor,
      }}>

        <Grid.Block start="2" span="12">
          <Flex container 
            justifyContent="space-between" 
            alignItems="center" 
            styles={{
              height: t.header.height[0],
              '@media (min-width: 1281px)': {height: t.header.height[1]}
            }}
          >
            <Flex>
              <Link to="/">
                <StaticImage src="../../../images/logos/horizontal-logo_small_full-color.png" alt="TCB Logo" loading="eager" height={38} css={{display: `block`, '@media (min-width: 1281px)': {display: `none`}}}/>
                <StaticImage src="../../../images/logos/horizontal-logo_small_full-color.png" alt="TCB Logo" loading="eager" height={38} css={{display: `none`, '@media (min-width: 1281px)': {display: `block`}}}/>
              </Link>
            </Flex>
            <Flex>
              <MobileNavMenuContainer forceRootMenu={forceRootMenu} />
              <DesktopNavMenu />
            </Flex>
          </Flex>
        </Grid.Block>
        <Grid.Block start="1" span="14"  css={{ display: `none`, '@media (min-width: 1080px)': {display: `block`}}}>
          <SectionNavMenu isSubMenuOpen={isSubMenuOpen} openAccountLink={openAccountLink}/>
        </Grid.Block>
      </Grid.FullWidthSection>
      
    </>
  )
  }


export default Header